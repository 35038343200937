import { DialogService }                      from 'aurelia-dialog';
import { bindable, customElement, inject }    from 'aurelia-framework';
import { PLATFORM }                           from 'aurelia-pal';
import { BaseViewModel }                      from 'base-view-model';
import { AttachZipSchema }                    from 'modules/aggregates/selected-bulletins/attach-zip-schema';
import { SelectedBulletinFilesRepository }    from 'modules/aggregates/selected-bulletins/services/files-repository';
import { SelectedBulletinsRepository }        from 'modules/aggregates/selected-bulletins/services/repository';
import { SelectedBulletinZipFilesRepository } from 'modules/aggregates/selected-bulletins/services/zip-files-repository';
import { TestsQuantitySchema }                from 'modules/aggregates/selected-bulletins/tests-quantity-schema';
import { AppContainer }                       from 'resources/services/app-container';

@customElement('selected-bulletins')
@inject(AppContainer, DialogService, SelectedBulletinsRepository, SelectedBulletinZipFilesRepository, AttachZipSchema, TestsQuantitySchema, SelectedBulletinFilesRepository)
export class SelectedBulletins extends BaseViewModel {

    listingId = 'test-selected-bulletins-listing';

    @bindable headerTitle = null;
    @bindable repository;
    @bindable alert;
    @bindable datatable;

    records;
    maxTestsQuantity = 0;
    readonly         = false;

    /**
     * Constructor
     *
     * @param appContainer
     * @param dialogService
     * @param selectedBulletinsRepository
     * @param zipFilesRepository
     * @param zipSchema
     * @param testsQuantitySchema
     */
    constructor(appContainer, dialogService, selectedBulletinsRepository, zipFilesRepository, zipSchema, testsQuantitySchema, filesRepository) {
        super(appContainer);

        this.dialogService       = dialogService;
        this.repository          = selectedBulletinsRepository;
        this.zipFilesRepository  = zipFilesRepository;
        this.zipSchema           = zipSchema;
        this.testsQuantitySchema = testsQuantitySchema;
        this.filesRepository     = filesRepository;

        this.self = this;
    }

    /**
     * Handles activate event
     */
    async activate(model) {
        super.activate();

        this.completeModel = model.completeModel;
        this.records       = model.records ?? [];
        this.isZip         = model.isZip ?? true;
        this.readonly      = model.readonly;

        this.initializeModel();

        if (this.isZip) {
            this.schema = this.zipSchema.schema(this);
        } else {
            if (!this.maxTestsQuantity)
                this.maxTestsQuantity = this.completeModel.max_tests_quantity || 0;

            this.schema = this.testsQuantitySchema.schema(this);
        }

        if (this.records.length === 0) {

            this.hideDatatable();
            this.alert = this.warningAlertMessage(this.appContainer.i18n.tr('form.message.please-select-bulletins-first'));
        }

        if (!this.modalJustClosed && !this.records) {
            await this.fetchData(null);
        }

        this.modalJustClosed = false;

        this.defineDatatable();
    }

    /**
     * Initializes the model
     */
    initializeModel() {
        this.model        = this.completeModel;
        this.initialModel = this.completeModel;
    }

    /**
     * Handles the event when the user clicks on the "Change" button
     *
     * @return {Promise<number>}
     */
    async selectedBulletinsChanged() {
        this.alert = null;
        this.hideDatatable(false);

        await this.fetchData(null);

        this.appContainer.eventAggregator.publish('datatable-must-be-reloaded', { listingId: this.listingId });

        return this.maxTestsQuantity;
    }

    /**
     * Fetches data
     *
     * @param params
     * @return {Promise<*>}
     */
    async fetchData(params) {

        let selectedBulletins = this.completeModel.selected_bulletins_ids
            ? this.completeModel.selected_bulletins_ids
            : [this.completeModel.selected_bulletins_id];

        if (!Array.isArray(selectedBulletins)) selectedBulletins = [selectedBulletins];

        return this.repository.getModels({
            bulletin_ids:   selectedBulletins,
            records:        this.records ?? null,
            test_header_id: this.completeModel.test_header_id ?? null,
            date:           this.completeModel.created_at ?? null,
        }).then(response => {
            this.records                          = response;
            this.completeModel.selected_bulletins = this.records;

            if (typeof this.records === 'undefined' || this.records.length === 0) {
                this.records = [];

                this.hideDatatable();
                this.alert = this.warningAlertMessage(this.appContainer.i18n.tr('form.message.please-select-bulletins-first'));
            } else if (!this.isZip) {
                this.maxTestsQuantity = this.records[0].tests_quantity;
            }
        });
    }

    /**
     * Handles the event when the user clicks on the "Attach Zip" button
     *
     * @return {Promise<Promise|Promise|*>}
     */
    async submit() {

        this.alert = null;

        if (!this.allRequiredFieldsAreFilled()) {
            return Promise.resolve([]);
        } else {
            await this.updateModel();
            this.initialModel.assign(this.model);

            this.appContainer.notifier.successNotice(this.appContainer.i18n.tr('form.message.success.zip-upload-succeeded'));

            this.hideDatatable();
        }

    }

    /**
     * Button "Cancel" is only shown when this.isZip === true
     * @returns {Promise<void>}
     */
    async cancelZip() {

        this.alert = null;

        // Only show confirmation if zip file was already confirmed
        if (this.completeModel.zip_file_id) {
            if (confirm(this.appContainer.i18n.tr('form.message.remove-uploaded-zip-file'))) {
                this.model.zip_file_id = null;
                this.updateModel();
                this.initialModel.assign(this.model);
                this.zipSchemaVisible = false;
                this.hideDatatable(false);
            }
        } else {
            this.zipSchemaVisible = false;
            this.hideDatatable(false);
        }
    }

    /**
     * Updates the model
     */
    updateModel() {
        this.completeModel.zip_file_id = this.model.zip_file_id;
    }

    /**
     * Check in the front-end if all required fields are filled
     * @returns {boolean}
     */
    allRequiredFieldsAreFilled() {
        let message = '';

        if (!this.model.zip_file_id) {
            message += this.getDescriptive('form.field.file');
        }

        if (message.length > 0) {
            this.alert = this.dangerAlertMessage(
                this.appContainer.i18n.tr('messages.action.failed'),
                message,
            );
        }

        return message.length === 0;

    }

    /**
     * Returns descriptive
     *
     * @param slug
     *
     * @returns String
     */
    getDescriptive(slug) {
        return '<li>' + this.appContainer.i18n.tr('validation.required', {
            attribute: this.appContainer.i18n.tr(slug),
        }) + '</li>';
    }

    /**
     * Defines datatable
     */
    defineDatatable() {
        this.datatable = {
            repository:         {
                search: this.search.bind(this),
            },
            destroySelected:    false,
            selectable:         false,
            showResultsPerPage: false,
            buttons:            [
                {
                    label:            'form.button.attach-zip',
                    icon:             'icon-file-zip',
                    color:            'teal',
                    outside_dropdown: true,
                    visible:          !this.readonly && this.isZip,
                    action:           () => this.zipSchemaVisible = !this.zipSchemaVisible,
                },
            ],
            sorting:            {
                column:    0,
                direction: 'asc',
            },
            columns:            [
                {
                    name:       'bulletin_name',
                    data:       'bulletin_name',
                    title:      'form.field.bulletin-name',
                    orderable:  false,
                    searchable: false,
                    type:       'processed',
                    processor:  row => this.prettifyBulletinName(row),
                },
                {
                    data:      'model_file',
                    name:      'model_file',
                    title:     'form.field.model-file',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/file/index'),
                    orderable: false,
                    center:    true,
                },
                {
                    data:      'is_test_uploaded',
                    name:      'is_test_uploaded',
                    title:     'form.field.is-test-uploaded',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/is-test-uploaded/index'),
                    orderable: false,
                    center:    true,
                },
                {
                    name:      'uploaded_file_id',
                    data:      'uploaded_file_id',
                    title:     'form.field.bulletin-file',
                    type:      'custom-cell',
                    viewModel: PLATFORM.moduleName('modules/aggregates/selected-bulletins/custom-listing-cells/upload-file/index'),
                    orderable: false,
                    center:    true,
                    hidden:    this.readonly,
                },
                {
                    name:      'operator_name',
                    data:      'operator_name',
                    title:     'form.field.operator-name',
                    type:      'input',
                    readonly:  this.readonly,
                    orderable: false,
                    center:    true,
                    onKeyUp:   (row, value, element) => {
                        let index = this.records.findIndex(item => item.bulletin_id === row.bulletin_id);

                        // If is first row, and value is not empty
                        if (index === 0 && value !== '') {
                            // Iterate through remaining rows and change their values
                            for (let i = 1; i < this.records.length; i++) {
                                if (!this.records[i].operator_name || this.records[i].operator_name === this.previousValue) {
                                    this.records[i].operator_name = value;
                                }
                            }

                            this.previousValue = value;
                        }
                    },
                },
            ],
        };
    }

    /**
     * Hides datatable
     *
     * @param hidden
     */
    hideDatatable(hidden = true) {
        this.datatableHidden = hidden;
    }

    /**
     * Performs a search given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    search(criteria) {
        let records = this.records;

        if (criteria.search.value !== null && criteria.search.value !== '') {
            let regExp = new RegExp(criteria.search.value, 'gi');

            records = this.records.filter((record) => {

                return ('' + record.bulletin_id).match(regExp)
                    || ('' + record.bulletin_name).match(regExp)
                    || ('' + record.norm).match(regExp)
                    || ('' + record.test_code).match(regExp)
                    || ('' + record.bulletin_revision_id).match(regExp)
                    || ('' + record.norm_edition).match(regExp)
                    || ('' + record.revision_number).match(regExp)
                    || ('' + record.bulletin_model).match(regExp)
            });
        }

        return Promise.resolve({
            data:            records,
            recordsTotal:    this.records.length,
            recordsFiltered: records.length,
        });
    }

    /**
     * Prettifies the bulletin name
     *
     * @param row
     * @return {string}
     */
    prettifyBulletinName(row) {
        let html = '<p class="mb-0" style="font-size: 13px">';
        html += row.bulletin_name;
        html += '</p>';
        html += '<span class="text-muted" style="font-size: 12px">';
        html += row.norm;
        html += '&nbsp;&bull;&nbsp;';
        html += row.bulletin_model;
        html += '&nbsp;&bull;&nbsp;(R';
        html += row.revision_number;
        html += ')';
        html += '</span>';
        return html;
    }

}
