import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class SelectedBulletinsRepository extends BaseRepository {
    baseUrl = 'test-selected-bulletins';

    /**
     * Retrieves template file IDs given some criteria
     *
     * @param criteria
     *
     * @returns {*}
     */
    getModels(criteria) {
        if (!criteria.bulletin_ids || criteria.bulletin_ids.length === 0)
            return Promise.resolve([]);
        return this.httpClient.post(`${this.baseUrl}/get-models`, criteria);
    }

    /**
     * Performs a search given some criteria, returns datatable
     *
     * @param testHeaderId
     * @param criteria
     *
     * @returns {*}
     */
    search(testHeaderId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/${testHeaderId}/search`, criteria);
    }
}
