import { inject }                          from 'aurelia-framework';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';
import { Downloader }                      from 'resources/services/downloader';

@inject(SelectedBulletinFilesRepository, Downloader)
export class SelectedBulletinFileCustomListingCell {
    /**
     * Constructor.
     *
     * @param filesRepository
     * @param downloader
     */
    constructor(filesRepository, downloader) {
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;
    }

    /**
     * Downloads the file
     */
    downloadFile() {
        const fileName = this.row.model_file.display_name.replace(/\.[^/.]+$/, '');

        this.filesRepository
            .download(this.row.model_file.id)
            .then((blob) => this.downloader.download(blob, fileName, this.row.model_file.extension));
    }
}
