import { inject }                          from 'aurelia-framework';
import { FormSchema }                      from 'modules/aggregates/selected-bulletins/custom-listing-cells/upload-file/form-schema';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';

@inject(SelectedBulletinFilesRepository, FormSchema)
export class UploadFileCustomListingCell {

    alert = null;

    constructor(filesRepository, formSchema) {
        this.filesRepository = filesRepository;
        this.formSchema      = formSchema;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.model  = model.row;

        this.schema = this.formSchema.schema(this)
    }
}
