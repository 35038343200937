import { BaseModel } from 'modules/core/models/base-model';

const WORK_CONTROL              = 1;
const MATERIAL_CHARACTERIZATION = 2;

export class AggControlType extends BaseModel {
    id   = null;
    name = null;

    get WORK_CONTROL() {
        return WORK_CONTROL;
    }

    get MATERIAL_CHARACTERIZATION() {
        return MATERIAL_CHARACTERIZATION;
    }
}
