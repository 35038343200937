import { TestSelectedBulletinsZip } from 'modules/aggregates/models/test-selected-bulletins-zip';

export class TestsQuantitySchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Returns a new instance of the model
     *
     * @returns {TestSelectedBulletinsZip}
     */
    model() {
        let model = new TestSelectedBulletinsZip();

        model.assign(this.modelDefaults);

        return model;
    }

    schema(viewModel, readonly = false) {

        this.tests_quantity = {
            type:       'number',
            key:        'tests_quantity',
            label:      'form.field.tests-quantity',
            size:       12,
            attributes: {
                readonly: readonly,
            },
        };

        return [
            [this.tests_quantity],
        ];
    }
}
