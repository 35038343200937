import { inject }                          from 'aurelia-framework';
import { SelectedBulletinFilesRepository } from 'modules/aggregates/selected-bulletins/services/files-repository';
import { ProcessStatus }                   from 'modules/core/models/process-status';
import { AppContainer }                    from 'resources/services/app-container';
import { Downloader }                      from 'resources/services/downloader';

@inject(AppContainer, SelectedBulletinFilesRepository, Downloader)
export class SelectedBulletinIsTestUploadedCustomListingCell {

    processing;
    icon;

    /**
     * Constructor.
     *
     * @param appContainer
     * @param filesRepository
     * @param downloader
     */
    constructor(appContainer, filesRepository, downloader) {
        this.appContainer    = appContainer;
        this.filesRepository = filesRepository;
        this.downloader      = downloader;
    }

    /**
     * Handles activate event
     *
     * @param model
     */
    activate(model) {
        this.column = model.column;
        this.row    = model.row;

        this.icon  = this._getIconByStatus();
        this.title = this._getTooltip();

        this.processing = this.row.process_status === ProcessStatus.IN_PROGRESS_WRITING_HEADERS_FOOTERS || this.row.process_status === ProcessStatus.IN_PROGRESS_READING;
    }

    /**
     * gets the icon by status
     *
     * @return {string}
     */
    _getIconByStatus() {
        switch (this.row.process_status) {
            case ProcessStatus.NOT_STARTED:
                return 'icon-file-empty2 text-muted';
            case ProcessStatus.FINISHED:
                return 'icon-file-check2 text-success';
            case ProcessStatus.INVALID || ProcessStatus.CANCELED:
                return 'icon-file-minus2 text-danger';
            case ProcessStatus.IN_PROGRESS_READING:
                return 'icon-file-stats2 text-warning';
            default:
                return 'icon-file-presentation2 text-blue';
        }
    }

    /**
     * Gets the tooltip
     *
     * @return {string}
     * @private
     */
    _getTooltip() {
        switch (this.row.process_status) {
            case ProcessStatus.NOT_STARTED:
                return 'form.messages.processing.not-started';
            case ProcessStatus.FINISHED:
                return 'form.messages.processing.completed';
            case ProcessStatus.INVALID:
                return this._invalidMessage();
            case ProcessStatus.CANCELED:
                return 'form.messages.processing.canceled';
            case ProcessStatus.IN_PROGRESS_READING || ProcessStatus.IN_PROGRESS_WRITING_HEADERS_FOOTERS:
                return 'form.messages.processing.in-progress';
            case ProcessStatus.IN_PROGRESS_FINISHED_READING || ProcessStatus.IN_PROGRESS_FINISHED_HEADERS_FOOTERS:
                return 'form.messages.processing.completed';
            default:
                return 'form.messages.unknown-status';
        }
    }

    /**
     * gets the invalid message
     *
     * @return {*}
     * @private
     */
    _invalidMessage() {
        let message = this.appContainer.i18n.tr('form.messages.processing.contains-invalid');

        if (this.row.processing_errors) {
            message += `<br>${this.row.processing_errors}`;
        }

        return message;
    }

    /**
     * Downloads the file
     */
    downloadFile() {
        const fileName = this.row.uploaded_file.display_name.replace(/\.[^/.]+$/, '');

        this.filesRepository
            .download(this.row.uploaded_file.id)
            .then((blob) => this.downloader.download(blob, fileName, this.row.uploaded_file.extension));
    }
}
