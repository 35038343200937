import { BaseModel } from 'modules/core/models/base-model';

export class BaseTestHeader extends BaseModel {
    test_header_id            = null;
    concession_id             = null;
    entity_id                 = null;
    team_id                   = null;
    highway_id                = null;
    intervention_type_id      = null;
    lot_intervention_id       = null;
    date                      = null;
    external_reference        = null;
    observations              = null;
}
