import { BaseTestHeader } from 'modules/aggregates/models/base-test-header';

export class BaseAggregatesTest extends BaseTestHeader {
    aggregates_test_id        = null;
    listing_revision_id       = null;
    material_type_id          = null;
    control_type_id           = null;
    part_zone_application_id  = null;
    layer_id                  = null;
    track_id                  = null;
    depth_quota               = null;
    axis_distance             = null;
    layer_thickness           = null;
    validated_by              = null;
    validated_at              = null;
    verified_inspection_by    = null;
    verified_inspection_at    = null;
    verified_concessionary_by = null;
    verified_concessionary_at = null;
}
