export class FormSchema {

    /**
     * Model default values
     */
    modelDefaults = {};

    /**
     * Returns form schema
     *
     * @param viewModel
     * @return {*}
     */
    schema(viewModel) {
        this.uploaded_file = {
            type:             'uniform-file',
            key:              'uploaded_file',
            url:              viewModel.filesRepository.uploadUri(),
            returnModel:      true,
            required:         false,
            mockFile:         viewModel.model.uploaded_file,
            allowedMimeTypes: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12, .xls, .xlsx, .xlsm, .xltx, .xltm, .xlsb, .xlam',
            size:             12,
            attributes:       {
                //customButtonClass: 'action btn btn-teal',
            },
        };
        return [
            [this.uploaded_file],
        ];
    }

}
